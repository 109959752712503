import React from 'react';
import slugify from 'slugify';
import { graphql } from 'gatsby';
import Seo from '../components/SEO';
import Layout from '../components/Layout';
import Breadcrumbs from '../components/Breadcrumbs';
import Product from '../components/Product';
import * as style from '../assets/style/pages/products-page.module.scss';

const ProductsPage = ({ location, data }) => {
  const offer = data.offer.edges.map(({ node }) => node)[0];
  const offerSlug = slugify(offer.title.value, { lower: true });
  const products = data.products.edges.map(({ node }) => node);

  const crumbs = [
    { title: 'Strona główna', url: '/' },
    { title: 'Oferta', url: '/oferta' },
    { title: offer.title.value, url: `/oferta/${offerSlug}` }
  ];

  return (
    <Layout>
      <Seo title={offer.title.value}>
        <script type="application/ld+json">
          {`{
            "@context": "http://schema.org",
            "@type": "BreadcrumbList",
            "itemListElement":
            [
              ${crumbs.map(({ title, url }, i) => `
                {
                  "@type": "ListItem",
                  "position": ${i + 1},
                  "item": {
                    "@type": "WebPage",
                    "@id": "${location.origin + url}",
                    "name": "${title}"
                  }
                }
              `)}
            ]
          }`}
        </script>
      </Seo>
      <Breadcrumbs crumbs={crumbs} />
      <section className={style.products}>
        <div className="container">
          <h1>{offer.title.value}</h1>
          <div className={`row ${style.products__row}`}>
            {products.map((product, i) => (
              <div className={`col-12 col-sm-6 col-lg-4 col-xl-3 ${style.products__col}`} key={product.id}>
                <Product product={product} url={`/oferta/${offerSlug}/${slugify(product.title.value, { lower: true })}`} />
              </div>
            ))}
          </div>
        </div>
      </section>
    </Layout>
  );
};

export const query = graphql`
  query($id: String) {
    products: allCockpitProducts(filter: {category: {value: {id: {eq: $id}}}}, sort: {fields: cockpitCreated, order: ASC}) {
      edges {
        node {
          id
          image {
            value {
              childImageSharp {
                gatsbyImageData(
                  width: 360,
                  height: 360,
                  quality: 100,
                  placeholder: BLURRED
                )
              }
            }
          }
          title {
            value
          }
          category {
            value {
              title {
                value
              }
            }
          }
        }
      }
    }
    offer: allCockpitOffer(filter: {id: {eq: $id}}) {
      edges {
        node {
          title {
            value
          }
        }
      }
    }
  }
`;

export default ProductsPage;
